<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="组织基础信息"
          :head-btn-options="headTopBtnOptions"
          @head-goback="handleHeadGoback"
        />
        <form-layout
          ref="formLayout"
          :column="formColumn"
          :dataForm.sync="nodeData"
        ></form-layout>
      </el-main>
    </el-container>
    <el-container>
      <el-main>
        <head-layout
          head-title="证件"
          :head-btn-options="headBtnOptions"
          @head-upload="handleUpload"
        />
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :editType="inline"
          @row-view="rowView"
          @row-download="rowDownload"
          @row-edit="rowEdit"
          @row-remove="rowRemove"
          @row-setting="rowSetting"
        >
          <template #fileName="{ row, index }">
            <!-- <span class="file-name" @click="rowView(row,index)">{{ row.fileName }}</span> -->
            <el-link type="primary" @click="rowView(row, index)">{{
              getFileName(row.fileName)
            }}</el-link>
          </template>
        </grid-layout>
      </el-main>
    </el-container>

    <el-dialog title="设置提醒" :visible.sync="dialogVisible">
      <el-form :model="remaindForm" label-width="120px">
        <el-form-item label="提前提醒天数">
          <el-input-number
            v-model="remaindForm.dateRemind"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="通知人">
          <el-input
            v-model="remaindForm.noticeUserName"
            ref="remaindFormUser"
            @focus="changeUser"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleCancel()"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog title="证件查看" :visible.sync="imageDiavlog">
      <pdf :src="fileUrl.src" v-if="fileUrl.type == 'pdf'"></pdf>
      <el-image :src="fileUrl.src" v-else></el-image>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="userVisible"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog ref="UserDetpDialog" @select-data="selectData" />
    </el-dialog>
    <cedForm ref="cedForm" @callback="callback" />
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import { getLazyTree } from "@/api/base/region";
import {
  relatedEnterprisePage,
  relatedEnterpriseSubmit,
  relatedEnterpriseRemove,
} from "@/api/responsibility/relatedCompanyEnterprise";
import { mapGetters } from "vuex";
import { downloadFileBlob } from "@/util/util";
import { dateFormat } from "@/util/date";
import pdf from "@/components/pdf/pdf.vue";
import cedForm from "./cedForm.vue";

export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    UserDetpDialog,
    pdf,
    cedForm,
  },

  data() {
    return {
      nodeData: {},
      pageType: "",
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      dialogVisible: false,
      remaindForm: {
        dateRemind: 1,
        noticeUserId: "",
        noticeUserName: "",
      },
      dialogLoading: false,
      settingId: "",
      // headers: { Authorization: this.$store.getters.token },
      fileUrl: "",
      imageDiavlog: false,
      userVisible: false,
      areaDic: [],
    };
  },

  created() {
    this.getAreaData();

    this.nodeData = JSON.parse(this.$route.query.row);
    this.pageType = this.$route.query.type;
    this.onLoad(this.page);
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
        downloadBtn: this.vaildData(
          this.permission.eduquestions_download,
          true
        ),
        settingBtn: this.vaildData(this.permission.eduquestions_setting, true),
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.pageType == "edit") {
        buttonBtn.push({
          label: "上传",
          emit: "head-upload",
          type: "button",
          icon: "",
          btnOptType: "upload",
        });
      }
      return buttonBtn;
    },
    headTopBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: "返回",
        emit: "head-goback",
        type: "button",
        icon: "",
      });
      return buttonBtn;
    },

    formColumn() {
      return [
        {
          label: "企业名称",
          prop: "companyName",
          labelWidth: 130,
          span: 8,
          disabled: true,
        },
        {
          label: "统一社会信用代码",
          prop: "companyCode",
          labelWidth: 130,
          span: 8,
          disabled: true,
        },
        {
          label: "成立日期",
          prop: "curDate",
          labelWidth: 130,
          span: 8,
          disabled: true,
        },
        {
          label: "法定代表人",
          prop: "legalRep",
          labelWidth: 130,
          span: 8,
          disabled: true,
        },
        {
          label: "注册位置",
          prop: "areaId",
          type: "cascader",
          dicData: this.areaDic,
          labelWidth: 130,
          span: 8,
          disabled: true,
        },
        {
          label: "详细位置",
          prop: "regLocation",
          labelWidth: 130,
          span: 8,
          disabled: true,
        },
      ];
    },

    tableOptions() {
      return {
        menuFixed: "right",
        customRowAddBtn: false,
        menuWidth: 260,
        selection: false,
        // linklabel: "fileName",
        column: [
          {
            label: "证件名称",
            prop: "fileName",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "证件类型",
            prop: "certificateType",
            align: "center",
            cell: true,
            type: "cascader",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary-tree?code=certificate_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
              checkStrictly: true,
            },
            rules: [{ required: true, message: "证件类型", trigger: "change" }],
          },
          {
            label: "证件编号",
            prop: "certificateCode",
            align: "center",
            overHidden: true,
            cell: true,
            maxlength: 255,
          },
          {
            label: "发证单位",
            prop: "certificateUnit",
            align: "center",
            overHidden: true,
            cell: true,
            maxlength: 255,
          },
          {
            label: "证件状态",
            prop: "certificateState",
            align: "center",
            overHidden: true,
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=certificate_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "发证日期",
            prop: "startDate",
            align: "center",
            cell: true,
            overHidden: true,
            rules: [{ required: true, message: "发证时间" }],
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          },
          {
            label: "到期日期",
            prop: "endDate",
            align: "center",
            cell: true,
            overHidden: true,
            rules: [{ required: true, message: "到期时间" }],
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          },
          {
            label: "上传人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },

    gridRowBtn(row) {
      let buttonBtn = [];
      if (this.permissionList.viewBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.downloadBtn) {
        buttonBtn.push({
          label: "下载",
          emit: "row-download",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.settingBtn) {
        buttonBtn.push({
          label: "设置提醒",
          emit: "row-setting",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
  },

  methods: {
    //   项目位置
    getAreaData(parentCode) {
      getLazyTree(parentCode).then((res) => {
        if (res.data.data && res.data.data.length != 0) {
          this.areaDic = this.generateOptions(res.data.data);
        }
      });
    },
    generateOptions(params) {
      var result = [];
      for (const param of params) {
        if (param.parentId == "10") {
          // 判断是否为顶层节点
          var parent = {
            id: param.id,
            label: param.title,
            value: param.id,
            title: param.title,
          };
          parent.children = this.getchilds(param.id, params); // 获取子节点
          result.push(parent);
        }
      }
      return result;
    },
    getchilds(id, array) {
      const childs = [];
      for (const arr of array) {
        // 循环获取子节点
        if (arr.parentId === id) {
          childs.push({
            id: arr.id,
            label: arr.title,
            value: arr.id,
            title: arr.title,
          });
        }
      }
      for (const child of childs) {
        // 获取子节点的子节点
        const childscopy = this.getchilds(child.id, array); // 递归获取子节点
        if (childscopy.length > 0) {
          child.children = childscopy;
        }
      }
      return childs;
    },
    handleHeadGoback() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      let queryObj = { ...this.query };
      delete queryObj.certificateType_search;
      params.companyCode = this.nodeData.companyCode;
      relatedEnterprisePage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, queryObj)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
    callback() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    rowEdit(row, index) {
      this.$refs.cedForm.init(row);
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (row.id) {
          relatedEnterpriseRemove(row.id).then(() => {
            this.onLoad(this.page);
            this.$message.success("操作成功！");
          });
        } else {
          this.tableData.splice(index, 0);
        }
      });
    },

    rowDownload(row) {
      downloadFileBlob(row.files, row.fileName);
    },
    rowView(row) {
      let pos = row.files.lastIndexOf(".");
      const type = row.files.slice(pos + 1, row.files.length);
      this.fileUrl = { src: row.files, name: row.fileName, type };
      this.imageDiavlog = true;
    },

    rowSetting(row) {
      this.remaindForm.dateRemind = row.dateRemind ? row.dateRemind : 1;
      this.remaindForm.noticeUserId = row.noticeUserId ? row.noticeUserId : "";
      this.remaindForm.noticeUserName = row.noticeUserName
        ? row.noticeUserName
        : "";
      this.settingId = row.id;
      this.dialogVisible = true;
    },
    // 设置提醒人
    changeUser() {
      this.userVisible = true;
      this.$refs.remaindFormUser.blur();
    },

    selectData(row, deptId, deptName) {
      // console.log("进行输出人员-------------------",row)
      const data = {
        userId: row.id,
        userName: row.realName,
        // orgId: deptId,
        // orgName: deptName,
        // sex: row.sex,
        // sexName: row.sexName
      };
      this.remaindForm.noticeUserId = row.id;
      this.remaindForm.noticeUserName = row.realName;
      this.userVisible = false;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleSave() {
      const { settingId, remaindForm } = this;
      remaindForm.id = settingId;
      relatedEnterpriseSubmit({ ...remaindForm }).then(() => {
        this.$message.success("操作成功");
        this.onLoad(this.page);
      });
      this.handleCancel();
    },

    handleUpload() {
      this.$refs.cedForm.init({
        companyCode: this.nodeData.companyCode,
      });
      // this.$refs.upload.$el.click()
    },
    getFileName(name) {
      let pos = name.lastIndexOf(".");
      return name.slice(0, pos);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
.headInfo {
  padding: 15px 12px 30px 12px;
  font-size: 14px;
  color: #3f4448;
  label {
    width: 100px;
  }
}
</style>
